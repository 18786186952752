import React from "react";
import { Helmet } from "react-helmet";
import "./Application.css";

class ComingSoon extends React.Component {
  render() {
    return (
      <div className="application" style={{ "text-align": "center" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ATX Creatives</title>
          <link rel="canonical" href="://atxcreatives.com/example" />
        </Helmet>
        <h2>
          <p style={{ padding: "30px" }}>ATX Creatives</p>
        </h2>
        <h4>
          <p>
            <em>Coming soon!</em>
          </p>
        </h4>
        <p style={{ padding: "50px" }}>
          ATX Creatives is a collection of Austin based artists and
          technologists. Arriving in late 2020!
        </p>
        <p style={{ "padding-top": "40en" }}>
          <small>
            made with <span role="image">❤️</span> in Austin, TX
          </small>
        </p>
      </div>
    );
  }
}

export default ComingSoon;
